.retangle {
  position: relative;
  border-radius: 27px;
  padding: 20px;
  transition: all 0.2s ease-in-out;
  margin-bottom: 40px;
  margin-top: 40px;
}

.retangle:hover,
.retangle:focus {
  transform: scale(1.05);
}

.retangle > img {
  width: 100%;
}
.retangle > div {
  padding-bottom: 57%;
  position: relative;
}

.retangle > div > p {
  font-weight: 700;
  font-size: 20px;
  position: absolute;
  top: 0%;
  height: 100%;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .retangle {
    padding: 10px;
  } 
}